<template>
  <div class="page flex" v-loading="loading">
    <div style="min-width: 300px; height: 600px">
      <el-carousel trigger="click" height="600px" width="300px">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-image
            fit="cover"
            :src="item"
            :preview-src-list="bannerList"
            :initial-index="index"
            style="width: 100%; height: 100%"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <el-form
      v-if="!loading"
      class="save-content"
      label-width="180px"
      :model="form"
      :rules="rules"
      ref="saveForm"
    >
      <el-form-item label="年度报告：">
        <el-radio-group v-model="form.enable">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
        <div class="form-tips">
          <i
            class="el-icon-info"
          />当前年度报告已有数据，下方配置仅提供部分年度报告数据更改
        </div>
      </el-form-item>
      <el-form-item label="展示方式：">
        <el-checkbox-group v-model="form.display_type">
          <el-checkbox label="banner"> 轮播图</el-checkbox>
          <el-checkbox label="dialog">首页弹窗</el-checkbox>
        </el-checkbox-group>
        <div class="form-tips">
          <i class="el-icon-info" />将在广告列表生成条年度报告的数据
        </div>
      </el-form-item>
      <div class="flex">
        <div style="min-width: 400px">
          <el-form-item label="加入会员数：" prop="design">
            <el-input-number
              :min="0"
              :precision="0"
              placeholder="请输入"
              v-model="form.members_count"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="收到会费笔数：" prop="design">
            <el-input-number
              :min="0"
              :precision="0"
              placeholder="请输入"
              v-model="form.orders_count"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="男士会员数：" prop="design">
            <el-input-number
              :min="0"
              :precision="0"
              placeholder="请输入"
              v-model="form.total_male_members_count"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="女士会员数：" prop="design">
            <el-input-number
              :min="0"
              :precision="0"
              placeholder="请输入"
              v-model="form.total_female_members_count"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="会员数最多地区：" prop="design">
            <el-cascader
              v-model="form.total_max_members_region"
              :options="areaData"
              :props="{ expandTrigger: 'hover', value: 'label', checkStrictly: true }"
              collapse-tags
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="共入驻企业数：" prop="design">
            <el-input-number
              :min="0"
              :precision="0"
              placeholder="请输入"
              v-model="form.total_companies_count"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="2023入驻企业数：" prop="design">
            <el-input-number
              :min="0"
              :precision="0"
              placeholder="请输入"
              v-model="form.companies_count"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="企业数最多地区：" prop="design">
            <el-cascader
              v-model="form.companies_max_count_region"
              :options="areaData"
              :props="{ expandTrigger: 'hover', value: 'label', checkStrictly: true }"
              collapse-tags
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="资源圈供需数：" prop="design">
            <el-input-number
              :min="0"
              :precision="0"
              placeholder="请输入"
              v-model="form.cyc_circles_count"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="发布最多供应的会员：" prop="design">
            <div class="flex">
              <el-button type="text" @click="maxSuppliesUserShow = true"
                >选择会员</el-button
              >
              <p
                style="margin-left: 12px"
                v-if="
                  form.cyc_circles_max_supplies_user &&
                  form.cyc_circles_max_supplies_user.id
                "
              >
                已选择：{{ form.cyc_circles_max_supplies_user.nickname }}
              </p>
              <SelectMember
                v-model="maxSuppliesUserShow"
                :selectType="0"
                @changeSelect="maxSuppliesUserChange"
                :hash="true"
              />
            </div>
          </el-form-item>
          <el-form-item label="发布最多需求的会员：" prop="design">
            <div class="flex">
              <el-button type="text" @click="maxDemandsUserShow = true"
                >选择会员</el-button
              >
              <p
                style="margin-left: 12px"
                v-if="
                  form.cyc_circles_max_demands_user &&
                  form.cyc_circles_max_demands_user.id
                "
              >
                已选择：{{ form.cyc_circles_max_demands_user.nickname }}
              </p>
              <SelectMember
                v-model="maxDemandsUserShow"
                :selectType="0"
                @changeSelect="maxDemandsUserChange"
                :hash="true"
              />
            </div>
          </el-form-item>
        </div>
        <div style="min-width: 400px">
          <el-form-item label="社区发布帖子数：" prop="design">
            <el-input-number
              :min="0"
              :precision="0"
              placeholder="请输入"
              v-model="form.circles_count"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="发布帖子最多的月份：" prop="design">
            <el-select placeholder="请选择" v-model="form.circles_max_month">
              <el-option
                v-for="item in monthOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发布帖子最多的会员：" prop="design">
            <div class="flex">
              <el-button type="text" @click="maxPostsUserShow = true"
                >选择会员</el-button
              >
              <p
                style="margin-left: 12px"
                v-if="
                  form.circles_max_posts_user &&
                  form.circles_max_posts_user.id
                "
              >
                已选择：{{ form.circles_max_posts_user.nickname }}
              </p>
              <SelectMember
                v-model="maxPostsUserShow"
                :selectType="0"
                @changeSelect="maxPostsUserChange"
                :hash="true"
              />
            </div>
          </el-form-item>
          <el-form-item label="组织举办活动数：" prop="design">
            <el-input-number
              :min="0"
              :precision="0"
              placeholder="请输入"
              v-model="form.activities_count"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="报名人数最多的活动：" prop="design">
            <div class="flex">
              <el-button type="text" @click="ActivitySelectorShow = true"
                >选择会员</el-button
              >
              <p
                style="margin-left: 12px"
                v-if="
                  form.activities_max_joined_activity &&
                  form.activities_max_joined_activity.id
                "
              >
                已选择：{{ form.activities_max_joined_activity.name }}
              </p>
              <ActivitySelector
                v-model="ActivitySelectorShow"
                :selected="[form.activities_max_joined_activity]"
                @selectActivity="selectActivity"
              />
            </div>
          </el-form-item>
          <el-form-item
            v-if="form.activities_max_joined_activity"
            label="活动报名人数："
            prop="design"
          >
            <el-input-number
              :min="0"
              :precision="0"
              placeholder="请输入"
              v-model="form.activities_max_joined_activity.activity_users_count"
            ></el-input-number>
            <div class="form-tips">
              <i class="el-icon-info" />活动报名人数为：报名人数最多的活动的人数
            </div>
          </el-form-item>
          <el-form-item label="发布文章数量：" prop="design">
            <el-input-number
              :min="0"
              :precision="0"
              placeholder="请输入"
              v-model="form.articles_count"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="文章浏览次数：" prop="design">
            <el-input-number
              :min="0"
              :precision="0"
              placeholder="请输入"
              v-model="form.articles_view_count"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="文章分享次数：" prop="design">
            <el-input-number
              :min="0"
              :precision="0"
              placeholder="请输入"
              v-model="form.articles_share_count"
            ></el-input-number>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <FixedActionBar>
      <el-button @click="getDatail(1)">恢复默认</el-button>
      <el-button @click="showCode = true">预览</el-button>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
    <Config :showCode="showCode" @closeCode="showCode = false" />
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
// 接口
import { getAnnualReportConfig, setAnnualReportConfig,getAnnualReportPreviewImages } from '../api/index.js'
import areaData from '@/modules/common/json/address.json'
import SelectMember from '@/modules/common/components/MemberSelector'
import ActivitySelector from '@/modules/activity/components/activity-album/ActivitySelector2.vue'
import Config from '../components/Config.vue'
export default {
  components: { FixedActionBar, SelectMember, ActivitySelector, Config },
  data() {
    return {
      areaData,
      monthOptions: [
        {
          value: '1',
          label: '1月',
        },
        {
          value: '2',
          label: '2月',
        },
        {
          value: '3',
          label: '3月',
        },
        {
          value: '4',
          label: '4月',
        },
        {
          value: '5',
          label: '5月',
        },
        {
          value: '6',
          label: '6月',
        },
        {
          value: '7',
          label: '7月',
        },
        {
          value: '8',
          label: '8月',
        },
        {
          value: '9',
          label: '9月',
        },
        {
          value: '10',
          label: '10月',
        },
        {
          value: '11',
          label: '11月',
        },
        {
          value: '12',
          label: '12月',
        },
      ],
      form: {
        display_type: [],
      },
      loading: true,
      //发布最多供应的会员
      maxSuppliesUserShow: false,
      //发布最多需求的会员
      maxDemandsUserShow: false,
      //   发布帖子最多的会员
      maxPostsUserShow: false,
      //报名人数最多的活动
      ActivitySelectorShow: false,
      // 预览二维码
      showCode: false,
      // 校验规则
      rules: {
        // design: [{ required: true, message: '请选择布局', trigger: 'change' }],
        // images: [{ required: true, message: '请上传图片', trigger: 'blur' }],
      },
      bannerList:[]
    }
  },

  methods: {
    // 上传图片
    handleMediaSrc(val) {
      console.log(val)
    },
    // 保存配置
    saveConfig() {
      let postData = {
        config: {
          ...this.form,
        },
      }
      postData.config.total_max_members_region =
        postData.config.total_max_members_region.join(',')
      postData.config.companies_max_count_region =
        postData.config.companies_max_count_region.join(',')
      this.loading = true
      setAnnualReportConfig(postData)
        .then((res) => {
          this.$message.success(res.msg)
          this.getDatail(0)
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 获取详情
    getDatail(restore_default) {
      this.loading = true
      getAnnualReportConfig({ restore_default })
        .then((res) => {
          this.form = res.data
          this.form.total_max_members_region =
            this.form.total_max_members_region.split(',')
          this.form.companies_max_count_region =
            this.form.companies_max_count_region.split(',')
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 获取轮播图图片
    getBannerList(){
      getAnnualReportPreviewImages().then(res=>{
        this.bannerList =res.data
      })
    },
    //发布最多需求的会员
    maxDemandsUserChange(val) {
      this.form.cyc_circles_max_demands_user = {
        id: val[0].user_id,
        nickname: val[0].nickname,
        avatar: val[0].avatar,
        title_name: val[0].title_name,
      }
    },
    //发布最多供应的会员
    maxSuppliesUserChange(val) {
      this.form.cyc_circles_max_supplies_user = {
        id: val[0].user_id,
        nickname: val[0].nickname,
        avatar: val[0].avatar,
        title_name: val[0].title_name,
      }
    },
    // 发布帖子最多的会员
    maxPostsUserChange(val) {
      this.form.circles_max_posts_user = {
        id: val[0].user_id,
        nickname: val[0].nickname,
        avatar: val[0].avatar,
        title_name: val[0].title_name,
      }
    },
    //报名人数最多的活动
    selectActivity(val) {
      console.log(val)
      this.form.activities_max_joined_activity = {
        id: val.hashid,
        name: val.name,
        cover: val.cover,
        activity_users_count: val.activity_users_count,
      }
    },
  },
  mounted() {
    this.getDatail(0)
    this.getBannerList()
  },
}
</script>

<style lang="scss" scoped>
.form-tips {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 1.5;
  margin-top: 8px;
  span + span {
    margin-left: 4px;
  }
}
::v-deep .el-carousel {
  .el-carousel__indicator {
    .el-carousel__button{
      width: 10px !important;
    height: 10px !important;
    border-radius: 9999em;
    }
    // padding: 0px !important;
    
  }
}
</style>
