import api from "@/base/utils/request";


export const getAnnualReportConfig = data => {
  return api({
    url: "/admin/admin/settings/getAnnualReportConfig",
    method: "post",
    data
  });
};

export const setAnnualReportConfig = data => {
  return api({
    url: "/admin/admin/settings/setAnnualReportConfig",
    method: "post",
    data
  });
};


export const getAnnualReportQrCode = data => {
  return api({
    url: "/admin/admin/settings/getAnnualReportQrCode",
    method: "post",
    data
  });
};

export const getAnnualReportPreviewImages = data => {
  return api({
    url: "/admin/admin/settings/getAnnualReportPreviewImages",
    method: "post",
    data
  });
};

